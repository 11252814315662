import React from "react"
import { Container, Row, Col, Tabs, Tab, Button } from "react-bootstrap";

import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/sections/banner"
import Blog from "../components/sections/blogs"
import InformationSection from "../components/sections/informational-section";
import OtherMIESolutions from "../components/sections/hubs/other-MIE-hub";
import FeaturedCaseStudy from "../components/sections/case-study/featured-case-study";

import userManagementAnalyticsIcon from "../images/co-working/info/user_mgmt_analytics.svg";
import realtimeMonitoringIcon from "../images/co-working/info/realtime_monitoring.svg";

import PrivateMeshTab from "../components/tabs/privatemesh-technology";
import CustomisableTab from "../components/tabs/customisable";

import riverCityLabsIcon from "../images/co-working/rivercitylabs.svg";


import studAccoIcon from "../images/other-mie/student_accom.png";
import buildToRentIcon from "../images/other-mie/buildtorent.png";
import hotelIcon from "../images/other-mie/hotels.png";
import publicWifiIcon from "../images/other-mie/public_wifi.png";

import bannerImage from "../images/box-headers/coworking.jpg";

import bgImg from "../images/headers/subheader_blue.svg";
import {getCategoryId} from "../utils/categories";

const categoryId = getCategoryId("Co-Working Space");

const CoWorking = () => (
  <Layout title="Co-working">
    <SEO title="Co-Working Spaces - Internet and Wi-Fi Platform - VostroNet">
      <meta name="description" content="VostroNet is the leader in providing high speed internet and secure wi-fi for co-working & shared workspaces. Manage users via our Q2 software platform." />
    </SEO>
    <Banner subpage bgImg={bgImg} data={{
      title: {
        name: "Co-working",
      },
      subtitles: [
        {
          name: `Software-defined Collaboration`,
          className: "fw-400",
        },
        {
          name: `Enhanced functionality and security for users within a shared workspace environment.`,
          className: "banner-text-small",
        },
      ],
      buttonData: {
        name: "Learn more",
      },
      img: bannerImage,
    }}
      hideScrollButton
      customButtons={(<>
        <Container>
          <Row>
            <Col xs="auto">
              <Button variant="green" className="btn-banner mb-3" rel="noopener" target="_blank" href="https://cdn.vostro.cloud/content/vostronet-datasheet-coworking.pdf">
                <i className="fas fa-file-pdf mr-2" />
                {"Co-working Datasheet"}
              </Button>
            </Col>
            <Col xs="auto">
              <Button variant="outline-dark" className="btn-banner-secondary" rel="noopener" target="_blank" href="https://cdn.vostro.cloud/content/vostronet-solutions-coworking.pdf">
                <i className="fas fa-binoculars mr-2" />
                {"Quick overview"}
              </Button>
            </Col>
          </Row>
        </Container>
      </>)} />
    <div className="general py-5">
      <Container>
        <Row>
          <Col>
            <div className="title mb-3">
              {"Innovative wireless environments"}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="desc my-4">
              {"The Q2 Software Platform™ is a cloud-based Wi-Fi management system that centralises and automates the management of a Wi-Fi environments. The software includes PrivateMesh™, which is unique technology that enables a single network to isolate unrelated users and devices while also permitting communication within authorised groups or organisations."}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    <InformationSection data={{
      title: "Building a smarter network",
      subtitle: "We deliver an advanced network that optimises the usability, security and performance of Wi-Fi for users and groups operating in co-working spaces.",
      subData: [
        {
          title: "User Management and Analytics",
          subtitle: "Our Q2 software platform offers advanced network analytics and useful user insights, providing management with powerful tools to enhance their offering.",
          img: userManagementAnalyticsIcon,
        },
        {
          title: "Real-time Monitoring",
          subtitle: "We monitor our network in real-time, ensuring we are able to respond quickly to network issues should they arise.",
          img: realtimeMonitoringIcon,
        },
      ],
    }} />

    <Container className="py-5 tab-section">
      <Row>
        <Col>
          <Tabs defaultActiveKey="mesh" id="public-wifi-tab-section">
            <Tab eventKey="mesh" title="PrivateMesh Technology">
              <PrivateMeshTab />
            </Tab>
            <Tab eventKey="customisable" title="Customisable">
              <CustomisableTab />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
    <FeaturedCaseStudy data={{
      link: "https://cdn.vostro.cloud/content/casestudy-coworking-rcl.pdf",
      title: "Featured case studies",
      desc: "Engineering a high-speed shared workspace solution for River City Labs…",
      img: riverCityLabsIcon,
      sideNote: "Operated by ACS, River City Labs is a co-working space and start-up incubatorin Fortitude Valley, Brisbane.",
    }} />
    <Blog category={categoryId} />
    <OtherMIESolutions data={[
      {
        label: "Public Wi-Fi",
        link: "/public-wifi",
        img: publicWifiIcon,
      },
      {
        label: "Student Accommodation",
        link: "/student-accommodation",
        img: studAccoIcon,
      },
      {
        label: "Build-to-Rent",
        link: "/build-to-rent",
        img: buildToRentIcon,
      },
      {
        label: "Hotels",
        link: "/hotel",
        img: hotelIcon,
      },
    ]} />
  </Layout>
)

export default CoWorking;
