import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import securityIcon from "../../images/co-working/tab/security_icon.svg";
import seamlessIcon from "../../images/co-working/tab/seamless_icon.svg";
import infoSharingIcon from "../../images/co-working/tab/infosharing_icon.svg";
import groupManagementIcon from "../../images/co-working/tab/grp_mgmt_icon.svg";

export default function PrivateMeshTab() {

  return (
    <div className="tab-mesh">
      <Container fluid className="p-4">
        <Row>
          <Col>
            <div className="tab-desc">
              {"Share information easily and securely between users and devices via VostroNet's PrivateMesh technology."}
            </div>
          </Col>
        </Row>
        {/* desc */}
        <Row className="align-items-center my-5">
          <Col>
            <img src={infoSharingIcon} alt="Information Sharing" className="tab-img mr-3" />
            <div className="tab-title">
              {"Information Sharing"}
            </div>
          </Col>
          <Col xs={12} lg={6} xl={8}>
            <div className="tab-desc">
              {"PrivateMesh enables secure and efficient collaboration in the workplace by using point-and-click Wi-Fi software which enables the creation of private groups of devices."}
            </div>
          </Col>
        </Row>

        <Row className="align-items-center my-5">
          <Col>
            <img src={seamlessIcon} alt="Information Sharing" className="tab-img mr-3" />
            <div className="tab-title">
              {"Seamless"}
            </div>
          </Col>
          <Col xs={12} lg={6} xl={8}>
            <div className="tab-desc">
              {"Without PrivateMesh, an administrator would have to manually set up a separate shared network (VLAN) for each group. In a co-working space environment, this could mean 20+ different Wi-Fi networks. The innovative technology allows for easy allocation of users and devices to a ‘PrivateMesh Group’"}
            </div>
          </Col>
        </Row>

        <Row className="align-items-center my-5">
          <Col>
            <img src={securityIcon} alt="Information Sharing" className="tab-img mr-3" />
            <div className="tab-title">
              {"Security"}
            </div>
          </Col>
          <Col xs={12} lg={6} xl={8}>
            <div className="tab-desc">
              {"PrivateMesh allows groups on the network to have separate secure networks within the Wi-Fi ecosystem."}
            </div>
          </Col>
        </Row>

        <Row className="align-items-center my-5">
          <Col>
            <img src={groupManagementIcon} alt="Information Sharing" className="tab-img mr-3" />
            <div className="tab-title">
              {"Group Management"}
            </div>
          </Col>
          <Col xs={12} lg={6} xl={8}>
            <div className="tab-desc">
              {"Our Platform allows the administration to allocate groups and devices to a PrivateMesh. Users and devices within this PrivateMesh can seamlessly share information and cast between each other."}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}