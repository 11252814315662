import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import customisable from "../../images/btr/tab/customisable.svg";
import generic_analytics from "../../images/btr/tab/generic_analytics.svg";
import networkdevice from "../../images/btr/tab/networkdevice.svg";

export default function CustomisableTab() {

  return (
    <div className="tab-connection">
      <Container fluid className="no-gutters p-4">
        <Row>
          <Col>
            <div className="tab-main-title mb-3">
              {"Tailored software platform"}
            </div>
            <div className="tab-desc">
              {"The Q2 Platform can be customised to suit your wireless environment. Powerful network tools to help you understand your shared spaces"}
            </div>
          </Col>
        </Row>
        <RowElement src={customisable}
          alt="high speed fibre"
          title="Customisable captive portal and landing page"
          text="Branding customised for easy onboarding. Easily adjusted by staff to provide up-to-date information, promotions and alerts to customers."
          />
        <RowElement src={networkdevice}
          alt="experience difference"
          title="Network device booking engine"
          text="Q2 Software includes a booking engine to enable automated access periods for network devices or meeting room assets, enabling groups to have access to a meeting room TV or other Wi-Fi enabled devices for the period in which it is booked."
          />
        <RowElement src={generic_analytics}
          alt="Integrated Wi-Fi Mesh"
          title="Generic user analytics"
          text="Generic information customised to your environment. Access customer utilisation(number of visits, time spent) of different areas: shared spaces, meeting room preference, preferred desk location and configurations."
          />
      </Container>
    </div>
  );
}



function RowElement(props) {
  const {title, alt, src, text} = props;
  return (<Row className="my-5 align-items-center">
  <Col xs={"auto"} lg={1} >
    <img src={src} className="tab-img mr-3" alt={alt} />
  </Col>
  <Col xs={10} lg={5} xl={3}>
    <div className="tab-title">
      {title}
    </div>
  </Col>
  <Col xs={12} lg={6} xl={8}>
    <div className="tab-desc">
      {text}
    </div>
  </Col>
</Row>)
}